interface ButtonAProps {
  title: string;
  text: string;
  href: string;
}

const ButtonA = (props: ButtonAProps) => {
  return (
    <div className="mx-auto">
      <a className="inline-block w-auto transition-all duration-150 ease-in-out bg-primary hover:shadow-lg shadow-md outline-none focus:outline-none text-white text-base py-3 px-10 rounded" 
        href={props.href} title={props.title}>
          {props.text}
      </a>
    </div>
  );
};

export default ButtonA;