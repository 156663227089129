
import { useFlags } from 'flagsmith-es/react';

const Flagsmith = () => {
  const flags = useFlags(['demo_flagsmith']); // only causes re-render if specified flag values / traits change  
  return (
    <div>
      {flags.demo_flagsmith?.enabled ? (
        <div className=" py-16">
            <div className="flex justify-center pb-8">
                <p>{flags.demo_flagsmith?.value}</p>
            </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}

export default Flagsmith