interface PartnershipProps {
  image: string;
  alt: string;
}

const Partnership = (props: PartnershipProps) => {
  return (
    <div className="flex-n max-w-xs md:w-1/2 lg:w-1/6 rounded overflow-hidden shadowlg m-2 md:m-6">
      <img className="grayscale-disabled h-full w-64 object-scale-down transition-all duration-200 ease-in-out" src={props.image} alt={props.alt} />
  </div>

  );
};

export default Partnership;