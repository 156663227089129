import React from 'react';
import Typed from 'typed.js';

function TypedComponent() {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['DevOps', 'SRE/Observability', 'Cloud Architect', 'Cybersecurity' ],
      typeSpeed: 80,
      backSpeed: 50,
    //   startDelay: 2000,
      backDelay: 1500,
      loop: true
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div className="sm:text-4xl text-3xl antialiased font-mono text-primary-dark">
      <span ref={el} />
    </div>
  );
}

export default TypedComponent;