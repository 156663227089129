const WhatDoIDo = () => {
  return (
    <>
        <div className="flex justify-center pb-8 px-4 md:px-0 lg:px-8 ">
            <h4 className="md:text-2xl text-xl">Pero... ¿y qué hago?</h4>
        </div>
        <div className="flex justify-start px-8 md:px-0 lg:px-20">
            <ul className="px-8 list-disc">
                <li className="mb-4 md:mb-1"><h2>Consultoría IT en casi todos los ámbitos imaginables</h2></li>
                <li className="mb-4 md:mb-1"><h2>Diseño de nuevas arquitecturas Cloud escalables</h2></li>
                <li className="mb-4 md:mb-1"><h2>Administración y protección de tus assets en el Cloud</h2></li>
                <li className="mb-4 md:mb-1"><h2>Sistemas de backup gestionados en el Cloud</h2></li>
                <li className="mb-4 md:mb-1"><h2>Soluciones de Ciberseguridad tanto de endpoint como en servidores</h2></li>
                <li className="mb-4 md:mb-1"><h2>Migración y adaptación de plataformas al Cloud</h2></li>
                <li className="mb-4 md:mb-1"><h2>Sistemas escalables, servicios premium de correo y mucho más</h2></li>
            </ul>
        </div>
        <div className="flex justify-center py-8">
            <div className="mx-auto text-primary-dark hover:text-primary transition-all duration-150 ease-in-out">
                <i className="fas fa-5x fa-cloud"></i>
            </div>
        </div>

        <div className="flex justify-center text-center py-8 px-4 md:px-0 lg:px-8">
            <h3 className="md:text-2xl text-xl">Todo esto está muy bien, pero... ¿qué le aportan este tipo de servicios a tu empresa?</h3>
        </div>
        <div className="flex justify-start px-8 md:px-0 lg:px-20">
            <ul className="px-8 list-disc">
                <li className="mb-4 md:mb-1"><h2>Control absoluto de la infraestructura/servidores utilizando proveedores líderes, como por ejemplo Amazon Web Services</h2></li>
                <li className="mb-4 md:mb-1"><h2>Soluciones pensadas en la seguridad desde el primer minuto</h2></li>
                <li className="mb-4 md:mb-1"><h2>Separación de servicios: di adiós al caos global, ya que cada servicio puede estar aislado sin repercutir en el funcionamiento de otros (en medida de lo posible)</h2></li>
                <li className="mb-4 md:mb-1"><h2>Optimización de los recursos y de los costes</h2></li>
                <li className="mb-4 md:mb-1"><h2>Utilizar tecnologías modernas y de vanguardia</h2></li>
                <li className="mb-4 md:mb-1"><h2>Monitoreo de los servidores y servicios a nivel fanático: conoce el estado real de tus servicios y servidores, y ayuda a encontrar cuellos de botella</h2></li>
                <li className="mb-4 md:mb-1"><h2>Observabilidad y trazabilidad: entiende cómo se comporta tu código, por dónde puedes mejorarlo, con qué interactúa y cuándo se introducen fallos nuevos. Aporta la visión que no sabías que podías tener.</h2></li>
            </ul>
        </div>
    </>


  );
};

export default WhatDoIDo;