import Link from "next/link";

interface HeaderProps {
  currentTab: string;
}

const Header = (props: HeaderProps) => {
  return (
    <nav className="bg-white shadow  w-full" role="navigation" id="top">
        <div className="container mx-auto p-4 flex flex-wrap items-center md:flex-no-wrap">
            <div className="mr-4 md:mr-8">
                <Link href="#" >
                  <img src="/me/logo.svg" height="54" width="54" alt="Logo Carles Javierre" />
                </Link>
            </div>
            {/* <div className="ml-auto md:hidden ">
                <button className="flex items-center px-3 py-2 border rounded outline-none focus:outline-none focus:border-primary text-primary-dark border-primary-dark" type="button">
                    <svg className="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path fill="currentColor" d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div> */}
            <div className="w-full md:w-auto md:flex-grow md:flex md:items-center transition-all" >
                <ul className="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:ml-auto md:mt-0 md:pt-0 md:border-0 font-mono text-lg">
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#about" title="Conóceme">Sobre mi</Link>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#services" title="Qué ofrezco">Servicios</Link>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#technologies" title="Con qué trabajo">Cómo trabajo</Link>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#contact" title="Hablemos" >Contacto</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>


  );
};

export default Header;