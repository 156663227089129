interface CardProps {
  title: string;
  text: string;
  image: string;
  alt: string;
}

const Card = (props: CardProps) => {
  return (
    <div className="flex-n max-w-xs rounded overflow-hidden hover:shadow-xl bg-white transition-all duration-150 ease-in-out shadow-lg m-6">
        <img className="w-full h-64 object-scale-down px-8 py-8" src={props.image} alt={props.alt}/>
        <div className="px-6 py-4 h-full bg-gray-100 border-t border-gray-400">
            <h3 className="font-bold text-xl mb-2">{props.title}</h3>
            <h4 className="text-gray-800 text-base">
                {props.text}
            </h4>
        </div>
    </div>

  );
};

export default Card;